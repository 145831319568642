import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../common/layout/layout";
import SEO from "../../common/seo";
import Gallery from "../../common/works/gallery";

const Paintings1980Page: FunctionComponent = () => {
  const query = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "works/paintings-1980" }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(quality: 75, layout: CONSTRAINED)
            }
            name
          }
        }
      }
    }
  `);

  return (
    <Layout backUrl="/works" backText="Works">
      <SEO title="Paintings 1980s" />
      <Gallery categoryTitle="Paintings 1980s" edges={query.allFile.edges} />
    </Layout>
  );
};

export default Paintings1980Page;
